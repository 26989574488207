import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import styled from "styled-components";

import { theme } from "../components/theme";

const {
  layout: { maxWidth },
  palette: { primary },
} = theme;

const HeaderStyled = styled.header`
  background: ${primary};
  margin-bottom: 1.45rem;
`;
const HeaderInner = styled.div`
  margin: 0 auto;
  max-width: ${maxWidth};
  padding: 1.45rem 1.0875rem;
`;
const StyledHeader = styled.h1`
  margin: 4px 0 0;
  font-family: "Trigger", sans-serif;
  text-shadow: 10px 10px black;
  transform-origin: bottom left;
  transform: rotate(-3deg);
`;
const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
`;

const Header = ({ siteTitle }) => (
  <HeaderStyled>
    <HeaderInner>
      <StyledHeader>
        <StyledLink to="/">{siteTitle}</StyledLink>
      </StyledHeader>
    </HeaderInner>
  </HeaderStyled>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
