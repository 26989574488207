import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Header from "./header";
import "./layout.css";

import { theme } from "../components/theme";

const {
  layout: { maxWidth },
} = theme;

const LayoutBody = styled.main`
  margin: 0 auto;
  max-width: ${maxWidth};
  padding: 0 1.0875rem 1.45rem;
`;

const Layout = ({ children }) => {
  return (
    <>
      <Header siteTitle="Triggerify me" />
      <LayoutBody>{children}</LayoutBody>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
