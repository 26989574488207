const theme = {
  palette: {
    borderGray: "#ddd",
    lightGray: "#bbb",
    mediumGray: "#ccc",
    primary: "orangered",
  },
  fonts: {
    textSize: "13px",
    headerSize: "24px",
    headerMediumSize: "20px",
    headerSmallSize: "14px",
  },
  layout: {
    maxWidth: "960px",
  },
};

export { theme };
